import React, { useEffect, useState, useRef, useReducer } from "react";
import { fetchRockets } from "../data/rocketFamilies.js";
import { customTheme } from "../utils.js";
import { fetchHomeInfo } from "../data/home.js";
import DarkBarChart from "../graphComponents/BarChart.js";
import CustomLegend from "../multipurpose/CustomLegend.js";
import dayjs from "dayjs";
import { ThemeProvider, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import StatCard from "../informationCards/StatCard.js";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Card from "../informationCards/InformationCard.js";
import { fetchPads } from "../data/pads.js";
import { fetchZones } from "../data/recoveryZones.js";
import LineScatterChart from "../graphComponents/LineScatter.js";

const Home = () => {
  const [rockets, setRockets] = useState([]);
  const [pads, setPads] = useState([]);
  const [zones, setZones] = useState([]);
  const [launchesPerVehicle, setLaunchesPerVehicle] = useState([]);
  const [launchesPerYear, setLaunchesPerYear] = useState({});
  const [turnaroundData, setTurnaroundData] = useState([]);
  const [xAxisForTurnarounds, setxAxisForTurnarounds] = useState([]);
  const [lineOfBestFitValues, setLineOfBestFitValues] = useState([]);
  const [, forceUpdate] = useReducer((x) => x + 1, 0); // Graph does not update on page resize; force update with this
  const [startDate, setStartDate] = useState(new Date(2020, 0, 1));
  const [selectedType, setSelectedType] = useState("exponential");
  const [chunkSize, setChunkSize] = useState(0);
  const [stats, setStats] = useState({
    numMissions: 0,
    numSuccesses: 0,
    numLandings: 0,
    shortestTimeBetweenFlights: 0,
    numStageReflights: 0,
  });
  const [lastLaunch, setLastLaunch] = useState(null);
  const [nextLaunch, setNextLaunch] = useState(null);

  const [totalLaunchesCurrentYear, setTotalLaunchesCurrentYear] = useState(0);
  const [totalLaunchesNextYear, setTotalLaunchesNextYear] = useState(0);

  const currentYear = new Date().getFullYear();

  const chartRef = useRef(null); // Ref to access the chart container
  const [dataLoaded, setDataLoaded] = useState(false); // Ref to signify when data has been loaded

  useEffect(() => {
    (async () => {
      const [padData, zoneData, rocketData] = await Promise.all([
        fetchPads("", {}),
        fetchZones("", {}),
        fetchRockets(),
      ]);

      setPads(padData);
      setZones(zoneData);
      setRockets(rocketData);
      setDataLoaded(true);
    })();
  }, []);

  useEffect(() => {
    if (!dataLoaded) {
      return;
    }
    const fetchData = async () => {
      const [homeData] = await Promise.all([fetchHomeInfo(startDate, selectedType)]);

      setTurnaroundData(homeData["turnaround_data"]);
      setxAxisForTurnarounds(homeData["turnaround_x_values"]);
      setLineOfBestFitValues(homeData["best_fit_turnaround_values"]);

      // Generate launches per vehicle per year graph:
      let seriesInputData = [];
      let totalLaunchesPerYear = [];
      const years = homeData["years"];
      for (const [key, value] of Object.entries(homeData["launches_per_rocket_per_year"])) {
        const launches = JSON.parse(value.replace(/'/g, '"'));

        if (totalLaunchesPerYear.length === 0) {
          totalLaunchesPerYear = [...launches];
        } else {
          totalLaunchesPerYear = totalLaunchesPerYear.map((launch, index) => launch + launches[index]);
        }

        const rocket = rockets.find((rocket) => String(rocket.id) === key);
        seriesInputData.push({
          data: launches,
          label: rocket.name,
          area: false,
          stack: "total",
          curve: "linear",
          color: rocket.color,
        });
      }

      const launchesPerYear = {};
      for (const [index, value] of Object.entries(totalLaunchesPerYear)) {
        launchesPerYear[years[index]] = value;
      }

      setLaunchesPerVehicle(seriesInputData);
      setLaunchesPerYear(launchesPerYear);

      // Set the new data fields
      setTotalLaunchesCurrentYear(homeData["total_launches_current_year"]);
      setTotalLaunchesNextYear(homeData["total_launches_next_year"]);
      setStats({
        numMissions: homeData["num_missions"],
        numSuccesses: homeData["num_successes"],
        numLandings: homeData["num_landings"],
        shortestTimeBetweenFlights: homeData["shortest_time_between_launches"],
        numStageReflights: homeData["num_stage_reflights"],
      });
      setNextLaunch(
        homeData.next_launch ? { ...homeData.next_launch, time: homeData.next_launch.time.slice(0, -1) } : null
      );
      setLastLaunch(
        homeData.last_launch ? { ...homeData.last_launch, time: homeData.last_launch.time.slice(0, -1) } : null
      );
      setChunkSize(homeData.chunk_size);
    };
    fetchData();
  }, [startDate, selectedType, dataLoaded, rockets]);

  useEffect(() => {
    const handleResize = () => {
      forceUpdate();
    };

    // Add resize event listener
    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleDateChange = (newDate) => {
    const date = dayjs(newDate);
    if (date.isValid()) {
      setStartDate(date.toDate());
    }
  };

  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
  };

  return (
    <div className="mx-auto graph-container">
      <div style={{ width: "100%" }}>
        <h2 className="centered-heading">Launches Per Year</h2>
        <div className="chart-and-stats" ref={chartRef}>
          <DarkBarChart
            seriesInput={launchesPerVehicle}
            xAxisInput={[{ data: Object.keys(launchesPerYear), type: "linear", scaleType: "band" }]}
            showLegend={true}
            chartWidth={chartRef.current?.offsetWidth || 1000}
            totalLaunches={launchesPerYear}
          />
        </div>
        <h2 className="centered-heading">Time Between Launches</h2>
        <div className="chart-and-stats" ref={chartRef}>
          <LineScatterChart
            seriesInput={[
              {
                id: "best fit",
                type: "line",
                data: lineOfBestFitValues,
                label: "Estimated (days)",
                area: false,
                curve: "natural",
                showMark: false,
                color: "#00CD9E",
              },
              {
                id: "data",
                type: "scatter",
                data: turnaroundData.map((y, index) => ({
                  x: xAxisForTurnarounds[index],
                  y,
                  id: `${index} scatter plot`,
                })),
                label: "Turnaround (days)",
                area: false,
                valueFormatter: (value, context) => {
                  if (value && value.y) {
                    return value.y;
                  }
                  return "";
                },
                color: "rgb(232, 144, 254)",
              },
            ]}
            xAxisInput={[
              {
                data: xAxisForTurnarounds,
                min: 0,
                max: xAxisForTurnarounds[xAxisForTurnarounds.length - 1],
              },
            ]}
            chartWidth={chartRef.current?.offsetWidth || 1000}
          />
          <div className="controls">
            <CustomLegend
              legendItems={[
                { color: "#E890FE", label: `${chunkSize} Launch Average` },
                { color: "#00CD9E", label: "Line of Best Fit" },
              ]}
            />
            <div className="date-picker">
              <ThemeProvider theme={customTheme}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <FormControl variant="outlined" margin="normal" sx={{ width: "160px" }} className="function-selector">
                    <DatePicker
                      id="start-date-picker"
                      minDate={dayjs("2006-01-01")}
                      maxDate={dayjs(new Date())}
                      value={dayjs(startDate)}
                      onChange={handleDateChange}
                      label="Start Date"
                    />
                  </FormControl>
                </LocalizationProvider>
              </ThemeProvider>
            </div>
            <ThemeProvider theme={customTheme}>
              <FormControl variant="outlined" margin="normal" sx={{ width: "150px" }} className="function-selector">
                <InputLabel id="function-type-label">Function Type</InputLabel>
                <Select
                  labelId="function-type-label"
                  id="function-type"
                  value={selectedType}
                  onChange={handleTypeChange}
                  label="Function Type"
                >
                  <MenuItem value="exponential">Exponential</MenuItem>
                  <MenuItem value="linear">Linear</MenuItem>
                  <MenuItem value="quadratic">Quadratic</MenuItem>
                  <MenuItem value="cubic">Cubic</MenuItem>
                </Select>
              </FormControl>
            </ThemeProvider>
          </div>
        </div>
      </div>
      <h2 className="centered-heading">Launch Estimates (From Graph)</h2>
      <div className="stat-cards-container">
        <StatCard title={`Total Launches ${currentYear}`} value={totalLaunchesCurrentYear} />
        <StatCard title={`Total Launches ${currentYear + 1}`} value={totalLaunchesNextYear} />
      </div>
      <h2 className="centered-heading">SpaceX Stats</h2>
      <div className="stat-cards-container">
        <StatCard title={"Missions"} value={stats["numMissions"]} />
        <StatCard title={"Successful Missions"} value={stats["numSuccesses"]} />
        <StatCard title={"Landings"} value={stats["numLandings"]} />
        <StatCard title={"Stage Reuses"} value={stats["numStageReflights"]} />
        <StatCard title={"Shortest Time Between Launches"} value={stats["shortestTimeBetweenFlights"]} />
      </div>
      {nextLaunch && (
        <div className="launch-container launch-width">
          <h2 className="centered-heading">Next Launch</h2>
          <Card key={nextLaunch.id} object={nextLaunch} objectType="launch" subObjects={{ rockets, pads, zones }} />
        </div>
      )}
      {lastLaunch && (
        <div className="launch-container launch-width">
          <h2 className="centered-heading">Last Launch</h2>
          <Card key={lastLaunch.id} object={lastLaunch} objectType="launch" subObjects={{ rockets, pads, zones }} />
        </div>
      )}
    </div>
  );
};

export default Home;
