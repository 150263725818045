import React, { useState, useEffect, useCallback, useRef } from "react";
import { fetchRockets } from "../data/rocketFamilies.js";
import { setAllValuesTrue, useDebounce } from "../utils.js";
import { fetchStages } from "../data/stages.js";
import { fetchSpacecraft } from "../data/spacecraft.js";
import { fetchPads } from "../data/pads.js";
import Filter from "../multipurpose/Filter.js";
import { FilterIcon } from "@heroicons/react/outline";
import Card from "../informationCards/InformationCard.js";
import { fetchZones } from "../data/recoveryZones.js";

const VehicleList = ({ type, familyID }) => {
  // Initialize state from URL parameters
  const initFromURL = (param, defaultValue = "") => {
    const params = new URLSearchParams(window.location.search);
    return params.get(param) || defaultValue;
  };

  // Function to get initial filter from URL or default to an empty object
  const initFilterFromURL = () => {
    const filterParam = initFromURL("filter");
    return filterParam ? JSON.parse(decodeURIComponent(filterParam)) : null;
  };

  // State declarations
  const [query, setQuery] = useState(initFromURL("query"));
  const [inputValue, setInputValue] = useState(initFromURL("query"));
  const [filter, setFilter] = useState(initFilterFromURL());
  const [currentPage, setCurrentPage] = useState(Number(initFromURL("page", 1)));
  const [numVehicles, setNumVehicles] = useState(0);
  const [rockets, setRockets] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [isFilterVisible, setIsFilterVisible] = useState(window.innerWidth >= 768);
  const [loading, setLoading] = useState(false);
  const [stageType] = useState(window.location.href.includes("boosters") ? "BOOSTER" : "SECOND_STAGE");
  const initLoad = useRef(filter ? false : true);

  const vehiclesPerPage = 25; // Number of vehicles to show per page

  const debouncedFilter = useDebounce(filter, 0);

  // Objects data and filter names for the Filter component
  const objectsData = { rocket: rockets };
  const filterNames = { version: "Version", rocket: "Rocket", status: "Status", type: "Type" };

  // Update the filter state and reset the page to 1
  const updateFilter = useCallback((newFilter) => {
    setFilter(newFilter);
    setCurrentPage(1);
  }, []);

  // Update the URL with the current filter, query, and page state
  const updateURL = useCallback(() => {
    const params = new URLSearchParams();
    params.set("filter", encodeURIComponent(JSON.stringify(debouncedFilter)));
    params.set("page", currentPage);
    params.set("query", query);
    window.history.replaceState({}, "", `${window.location.pathname}?${params}`);
  }, [debouncedFilter, currentPage, query]);

  // Call updateURL whenever query, currentPage, or filter changes
  useEffect(() => {
    updateURL();
  }, [query, currentPage, debouncedFilter, updateURL]);

  // Fetch and initialize data based on the type and familyID
  useEffect(() => {
    (async () => {
      if (debouncedFilter && initLoad.current) {
        initLoad.current = false;
        return;
      }
      setLoading(true);

      let vehicleData = [];
      let family = window.location.pathname.split("/")[1];
      let startFilter = null;

      if (type === "stage") {
        const stageData = await fetchStages(query, debouncedFilter, family, stageType);
        const rocketData = await fetchRockets();

        startFilter = stageData["start_filter"];
        vehicleData = stageData["stages"];
        setRockets(rocketData);
      } else if (type === "spacecraft") {
        family = window.location.pathname.split("/")[1];
        const spacecraftData = await fetchSpacecraft(query, debouncedFilter, family);
        startFilter = spacecraftData["start_filter"];
        vehicleData = spacecraftData["spacecraft"];
      } else if (type === "pad") {
        vehicleData = await fetchPads(query, debouncedFilter);
      } else if (type === "recoveryzone") {
        vehicleData = await fetchZones(query, debouncedFilter);
      }

      // Set default filter if none is provided
      if (!debouncedFilter) {
        // Dict's value comes from API as string, so create object
        for (const key in startFilter) {
          if (startFilter.hasOwnProperty(key)) {
            startFilter[key] = JSON.parse(
              startFilter[`${key}`].replace(/'/g, '"').replace(/True/g, "true").replace(/False/g, "false")
            );
          }
        }
        setFilter(startFilter);
      }
      setVehicles(vehicleData);
      setNumVehicles(vehicleData.length);
      setLoading(false);
    })();
  }, [query, debouncedFilter, type, familyID, stageType]);

  // Calculate total pages for pagination
  const totalPages = Math.ceil(numVehicles / vehiclesPerPage);

  // Handler for page changes
  const handlePageChange = useCallback(
    (delta) => {
      setCurrentPage((prev) => Math.max(1, Math.min(prev + delta, totalPages)));
    },
    [totalPages]
  );

  // Search handler
  const handleSearch = useCallback(() => {
    setQuery(inputValue);
    setCurrentPage(1);
  }, [inputValue]);

  // Handle Enter key for search
  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === "Enter") {
        handleSearch();
      }
    },
    [handleSearch]
  );

  // Toggle filter visibility
  const toggleFilterVisibility = useCallback(() => {
    setIsFilterVisible((prev) => !prev);
  }, []);

  // Reset filters
  const resetFilters = () => {
    const newFilter = setAllValuesTrue(filter);
    setFilter(newFilter);
    setQuery("");
    setInputValue("");
    setCurrentPage(1);
  };

  // Paginate vehicles for current page
  const paginatedVehicles = vehicles.slice((currentPage - 1) * vehiclesPerPage, currentPage * vehiclesPerPage);

  return (
    <div className="max-w-6xl mx-auto page-container">
      <div className="left-container">
        <div className="filter-container">
          <input
            type="text"
            name="q"
            placeholder="Search"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={handleKeyDown}
            className="search-input"
          />
          <button onClick={handleSearch} className="search-button" style={{ marginRight: "5px" }}>
            Search
          </button>
          <button onClick={resetFilters} className="clear-button">
            Clear
          </button>
        </div>
        <div className="pagination-container">
          <h2 className="text-xl mb-2 bold">
            Page {currentPage} of {totalPages}
          </h2>
          <div className="pagination-buttons-filter">
            <div className="pagination-buttons">
              <button
                className="pagination-button"
                onClick={() => handlePageChange(-currentPage + 1)}
                disabled={currentPage === 1}
              >
                «
              </button>
              <button className="pagination-button" onClick={() => handlePageChange(-1)} disabled={currentPage === 1}>
                ‹
              </button>
              <button
                className="pagination-button"
                onClick={() => handlePageChange(1)}
                disabled={currentPage >= totalPages}
              >
                ›
              </button>
              <button
                className="pagination-button"
                onClick={() => handlePageChange(totalPages - currentPage)}
                disabled={currentPage >= totalPages}
              >
                »
              </button>
            </div>
            <button className="filter-toggle-button" onClick={toggleFilterVisibility}>
              <FilterIcon className="h-6 w-6 text-gray-300" />
            </button>
          </div>
        </div>
        <div className="filter-width lg:w-1/4 lg:mr-4 lg:mt-0">
          <Filter
            filter={filter}
            updateFilter={updateFilter}
            filterNames={filterNames}
            objects={objectsData}
            isVisible={isFilterVisible}
          />
        </div>
      </div>
      <div className="launch-container">
        <div className="launch-cards-container">
          {loading ? (
            <div className="loading-indicator"></div>
          ) : paginatedVehicles.length > 0 ? (
            paginatedVehicles.map((vehicle) => (
              <Card
                key={vehicle.id}
                object={vehicle}
                objectType={type}
                subObjects={{ rockets }}
                vehicleType={type}
                additionalInfo={{}}
              />
            ))
          ) : (
            <p>No vehicles are available.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default VehicleList;
