import { getHostName, convertToUTC } from "../utils";

export async function fetchRocketFamilies() {
  const data = await fetch(`${getHostName()}/api/rocketfamilies/?format=json`);
  return await data.json();
}

export async function fetchRockets() {
  const data = await fetch(`${getHostName()}/api/rockets/?format=json`);
  return await data.json();
}

export async function fetchFamilyInfo(family, startDate) {
  const data = await fetch(
    `${getHostName()}/api/familyinformation/?family=${family}${
      startDate ? `&startdate=${convertToUTC(startDate)}` : ""
    }&format=json`
  );

  return await data.json();
}
