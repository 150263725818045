import React, { useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { ChartContainer, ChartsTooltip, LinePlot } from "@mui/x-charts";
import { ScatterPlot } from "@mui/x-charts/ScatterChart";
import { ChartsYAxis } from "@mui/x-charts/ChartsYAxis";
import "./DynamicChart.css";

export default function LineScatterChart({ seriesInput, xAxisInput, showxAxis, chartWidth = 1000 }) {
  // darkTheme is mui default that changes the graph labels to be white instead of black, increasing readability
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  // Update the xAxis input with styling/functions
  const updatedXAxisInput = xAxisInput.map((xAxis) => ({
    ...xAxis,
    valueFormatter: (value) => value,
  }));

  const chartProps = {
    xAxis: updatedXAxisInput,
    height: 600,
    width: chartWidth + 40, // x-charts leaves room for the right axis, even if it is null; hence the 40px width increase (and negative margin in CSS)
    series: seriesInput,
  };

  // State to track the hover or touch position
  const [hoverPosition, setHoverPosition] = useState(null);

  // Mouse and touch event handlers
  const handleMouseMove = (event) => {
    const { offsetX } = event.nativeEvent;
    setHoverPosition(offsetX);
  };

  const handleMouseLeave = () => {
    setHoverPosition(null);
  };

  const handleTouchMove = (event) => {
    const touch = event.touches[0];
    const boundingRect = event.target.getBoundingClientRect();
    const offsetX = touch.clientX - boundingRect.left;
    setHoverPosition(offsetX);
  };

  const handleTouchEnd = () => {
    setHoverPosition(null);
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <div className="chart-container">
        <div className="chart-inner-container">
          <div className="chart" style={showxAxis ? { marginBottom: "-10px" } : {}}>
            <ChartContainer
              {...chartProps}
              onMouseMove={handleMouseMove}
              onMouseLeave={handleMouseLeave}
              onTouchMove={handleTouchMove}
              onTouchEnd={handleTouchEnd}
              sx={{ "&&": { touchAction: "auto" } }}
            >
              <LinePlot />
              <ScatterPlot />
              <ChartsYAxis />

              {hoverPosition !== null && (
                <line
                  x1={hoverPosition}
                  y1={0}
                  x2={hoverPosition}
                  y2={600} // Height of the chart container
                  stroke="#b0b0c0"
                  strokeWidth={2}
                  strokeDasharray="5 5"
                />
              )}
              <ChartsTooltip trigger="axis" />
            </ChartContainer>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}
