import React, { useState, useEffect, useCallback, useRef } from "react";
import { format, startOfMonth, endOfMonth, addDays, isSameDay, startOfWeek } from "date-fns";
import "./LaunchCalendar.css";
import { fetchRocketFamilies } from "../data/rocketFamilies";
import { getCalendarInformation } from "../data/stats";
import { changeTimeToLocal } from "../utils";
import Filter from "../multipurpose/Filter.js";

const LaunchCalendar = () => {
  // init filter from URL (if it exists, else return empty dict)
  function initFilter() {
    const params = new URLSearchParams(window.location.search);
    const filterParam = params.get("filter");
    if (filterParam) {
      try {
        return JSON.parse(decodeURIComponent(filterParam));
      } catch (error) {
        console.error("Error parsing filter from URL:", error);
      }
    }
    return null;
  }

  const today = new Date();

  const [rocketFamilies, setRocketFamilies] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(today.getMonth());
  const [selectedDate, setSelectedDate] = useState(today);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
  const [rockets, setRockets] = useState([]);
  const [launches, setLaunches] = useState([]);
  const [stats, setStats] = useState([]);
  const [filter, setFilter] = useState(initFilter());
  const [dataLoaded, setDataLoaded] = useState(false);

  // Obj to store backend names with front end display name
  const filterNames = {
    rocket__family: "Rocket Family",
  };

  // Holds objects for filter to access values of (color, name, nickname, etc.)
  const objectsData = {
    rocket__family: rocketFamilies,
    rocket: rockets,
  };

  const updateFilter = useCallback((newFilter) => {
    setFilter(newFilter);
  }, []);

  // Set data for filter on page load on page load
  useEffect(() => {
    (async () => {
      const [rocketFamilyData] = await Promise.all([fetchRocketFamilies()]);
      setRocketFamilies(rocketFamilyData);

      const rocketsInData = rocketFamilyData.flatMap((family) =>
        family.rockets.map((rocket) => ({ ...rocket, familyId: family.id }))
      );

      setRockets(rocketsInData);
      setDataLoaded(true);
    })();
  }, []);

  // Function to fetch initial data
  const fetchInitialFilter = useCallback(async () => {
    if (filter || !dataLoaded) {
      return;
    }

    // If the filter was not init by the url, init it
    // Initialize filters
    const initialRocketFamilyFilter = rocketFamilies.reduce((acc, family) => {
      acc[family.id] = { hide__rocket: Object.fromEntries(family.rockets.map((rocket) => [rocket.id, true])) };
      return acc;
    }, {});

    setFilter({
      rocket__family: initialRocketFamilyFilter,
    });
  }, [dataLoaded, filter, rocketFamilies]);

  // Fetch initial data on component mount
  useEffect(() => {
    fetchInitialFilter();
  }, [fetchInitialFilter, dataLoaded]);

  useEffect(() => {
    if (!filter || !dataLoaded) {
      return;
    }
    (async () => {
      const calendarStatsData = await getCalendarInformation(filter);
      const launchData = changeTimeToLocal(calendarStatsData["launches"]);
      setStats(calendarStatsData);
      setLaunches(launchData);
    })();
  }, [filter, dataLoaded]);

  // ensures responsive behavior of window
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Init months; index needed so the same year is cycled between; based on development time, 2024 is used since it's a leap year
  const months = [
    { name: "January", index: 0 },
    { name: "February", index: 1 },
    { name: "March", index: 2 },
    { name: "April", index: 3 },
    { name: "May", index: 4 },
    { name: "June", index: 5 },
    { name: "July", index: 6 },
    { name: "August", index: 7 },
    { name: "September", index: 8 },
    { name: "October", index: 9 },
    { name: "November", index: 10 },
    { name: "December", index: 11 },
  ];

  // logic to get number of days and push them to a list
  const currentMonthObj = months[currentMonth];
  const startDate = startOfMonth(new Date(2024, currentMonthObj.index));
  const endDate = endOfMonth(new Date(2024, currentMonthObj.index));
  const days = [];

  let currentDay = startOfWeek(startDate);
  while (currentDay <= endDate) {
    if (currentDay.getMonth() === currentMonthObj.index) {
      days.push(currentDay);
    }
    currentDay = addDays(currentDay, 1);
  }

  const handlePrevMonth = () => setCurrentMonth((prevMonth) => (prevMonth === 0 ? 11 : prevMonth - 1));
  const handleNextMonth = () => setCurrentMonth((prevMonth) => (prevMonth === 11 ? 0 : prevMonth + 1));
  const handleDateClick = (date) => setSelectedDate(date);

  // Finds all launches on selected date
  // Shared logic function to filter and format launches
  const getFormattedLaunches = (date, hyperlink) => {
    // Find launches for the given date
    const launchesForDate = launches.filter((launch) => {
      return (
        new Date(launch.time).getDate() === date?.getDate() && new Date(launch.time).getMonth() === date?.getMonth()
      );
    });

    // Format the launches
    return launchesForDate.map((launch) => (
      <a href={`/launch/${launch.id}/`} key={launch.id} style={{ pointerEvents: hyperlink ? "auto" : "none" }}>
        <div
          className={`p-1 text-white rounded mt-1`}
          style={{
            backgroundColor: rockets.find((rocket) => rocket.id === launch.rocket).color,
          }}
        >
          {`${launch.name} (${new Date(launch.time).getFullYear()})`}
        </div>
      </a>
    ));
  };

  // Calendar view function
  const renderCalendarView = (formattedLaunches, date) => {
    let calendarLaunchInformation = [];

    // For mobile view, show a dot to indicate launches; otherwise, show details of up to 3 launches
    if (isMobileView && formattedLaunches.length > 0) {
      calendarLaunchInformation.push(<div className="dot" key="dot"></div>);
    } else {
      calendarLaunchInformation = formattedLaunches.slice(0, 3);
      // Add a "show more" tag if there are more than 3 launches
      if (formattedLaunches.length > 3) {
        const moreTag = (
          <div key={date.getDate() + "more"}>
            <p style={{ color: "lightgray", textAlign: "center" }}>{formattedLaunches.length - 3} more</p>
          </div>
        );
        calendarLaunchInformation.push(moreTag);
      }
    }

    return calendarLaunchInformation;
  };

  // holds launches for bottom section
  const bottomLaunches = getFormattedLaunches(selectedDate, true);

  return (
    <div className="p-4 max-w-6xl mx-auto bg-gray-800 text-white rounded-lg">
      <div className="flex justify-between items-center mb-4">
        <button onClick={handlePrevMonth} className="p-2 bg-gray-700 rounded">
          Prev
        </button>
        <div className="month text-2xl">{currentMonthObj.name}</div>
        <button onClick={handleNextMonth} className="p-2 bg-gray-700 rounded">
          Next
        </button>
      </div>
      <div className="grid grid-cols-7 gap-4 mb-4">
        {days.map((day, index) => (
          <div
            key={index}
            className={`p-2 rounded-lg ${
              day.getDate() === today.getDate() && day.getMonth() === today.getMonth() ? "bg-slate-500" : "bg-gray-700"
            } ${isSameDay(day, selectedDate) ? "ring-2 ring-blue-300" : ""}`}
            onClick={() => handleDateClick(day)}
          >
            {format(day, "d")}
            <div className="mt-1">{renderCalendarView(getFormattedLaunches(day, false), day)}</div>
          </div>
        ))}
      </div>
      {selectedDate && (
        <div className="events-and-filter lg:flex-row w-full center mt-4" style={{ rowGap: "10px" }}>
          <div className="filter filter-width lg:w-1/4 lg:mr-4 lg:mt-0">
            <Filter
              filter={filter}
              updateFilter={updateFilter}
              filterNames={filterNames}
              objects={objectsData}
              isVisible={true}
            ></Filter>
          </div>
          <div className="events events-width center">
            <div>
              <h2 className="text-xl mb-2 bold center lg:text-left">Launches on {format(selectedDate, "MMMM d")}:</h2>
              {bottomLaunches.length > 0 ? bottomLaunches : <div>No launches</div>}
            </div>
          </div>
        </div>
      )}
      <div>
        <h2 className="mb-2 bold" style={{ fontSize: "2rem", fontWeight: "bold", textAlign: "center" }}>
          Statistics:
        </h2>
        <div className="stats-table-container">
          <table className="stats-table">
            <tbody>
              <tr>
                <td className="stats-table-label">Days launched on:</td>
                <td className="stats-table-value">
                  {stats.num_days_with_launches} ({stats.percentage_days_with_launches}%)
                </td>
              </tr>
              <tr>
                <td className="stats-table-label">
                  {stats.days_with_most_launches?.includes("and") ? "Days" : "Day"} with most launches:
                </td>
                <td className="stats-table-value">
                  {stats.days_with_most_launches} ({stats.most_launches}{" "}
                  {stats.most_launches === 1 ? "launch" : "launches"})
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default LaunchCalendar;
