import { getHostName } from "../utils";

export async function fetchLaunches(query, filter, page, orderBy) {
  const jsonString = encodeURIComponent(JSON.stringify(filter));
  const data = await fetch(
    `${getHostName()}/api/launches/?page=${page}&format=json&filter=${jsonString}&query=${query}&order_by=${orderBy}`
  );
  return await data.json();
}

export async function fetchLaunchesOnly(filter) {
  const jsonString = encodeURIComponent(JSON.stringify(filter));
  const data = await fetch(`${getHostName()}/api/launchesonly/?format=json&filter=${jsonString}`);
  return await data.json();
}

export async function fetchLaunchInfo(id) {
  const response = await fetch(`${getHostName()}/api/launchinfo/?id=${id}&format=json`);

  if (!response.ok) {
    throw new Error("Launch Not Found");
  }

  return await response.json();
}

export async function fetchNewLaunchInfo(id) {
  const response = await fetch(`${getHostName()}/api/launch/?id=${id}&format=json`);

  if (!response.ok) {
    throw new Error("Launch Not Found");
  }

  return await response.json();
}
