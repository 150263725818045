import React from "react";
import { Link } from "react-router-dom";
import { formatTime, toTitleCase, makeRecoveryString, getPhotoLocation } from "../utils";
import "./Card.css";

// Function to set card data for different object types
const setCardData = (object, objectType, subObjects) => {
  const cardData = {};

  switch (objectType) {
    case "launch":
      const recoveryString = makeRecoveryString(object.recoveries, subObjects.zones);

      cardData["Rocket: "] = subObjects.rockets.find((rocket) => rocket.id === object.rocket)?.name;
      cardData["Liftoff Time: "] = formatTime(object.time);
      cardData["Launch Site: "] = subObjects.pads.find((pad) => pad.id === object.pad)?.nickname;
      cardData[`Stage${object.stages_string?.includes("and") ? "s" : "" || ""}: `] = object.stages_string;
      cardData[`Landing Location${recoveryString?.includes("and") ? "s" : ""}: `] = recoveryString;
      cardData[`Outcome: `] = toTitleCase(object.launch_outcome).replace("Tbd", "TBD");
      break;

    case "stage":
      cardData["Rocket: "] = subObjects.rockets.find((rocket) => rocket.id === object.rocket)?.name;
      cardData["Type: "] = toTitleCase(object.type.replace("_", " "));
      cardData["Status: "] = toTitleCase(object.status);
      cardData["Version: "] = object.version;
      cardData["Number of Launches: "] = object.num_launches;
      break;

    case "spacecraft":
      cardData["Nickname: "] = object.nickname || "N/A";
      cardData["Type: "] = toTitleCase(object.type);
      cardData["Status: "] = toTitleCase(object.status);
      cardData["Version: "] = object.version;
      cardData["Number of Launches: "] = object.num_launches;
      break;

    case "spacecraftInfo":
      cardData["Time: "] = formatTime(object.launch.time.slice(0, -1));
      cardData["Splashdown Time: "] = object.splashdown_time ? formatTime(object.splashdown_time?.slice(0, -1)) : "TBD";
      cardData["Turnaround Time: "] = object.spacecraft_turnaround
        ? `${Math.round((object.spacecraft_turnaround / 86400) * 100) / 100} days`
        : "None";
      cardData["Recovery Ship: "] = subObjects.boats.find((boat) => boat.id === object.recovery_boat)?.name || "TBD";
      break;

    case "stageInfo":
      cardData["Time: "] = formatTime(object.launch.time.slice(0, -1));
      cardData["Turnaround Time: "] = object.stage_turnaround
        ? `${Math.round((object.stage_turnaround / 86400) * 100) / 100} days`
        : "None";
      cardData["Landing Method: "] = `${toTitleCase(object.method?.replace("_", " "))?.replace(
        "Drone Ship",
        "ASDS"
      )} (${toTitleCase(object.method_success?.replace("_", " ")).replace("Tbd", "TBD")})`;
      cardData["Landing Location: "] =
        subObjects.zones.find((zone) => zone.id === object.landing_zone)?.nickname || "N/A";
      break;

    case "pad":
      cardData["Nickname: "] = object.nickname;
      cardData["Location: "] = object.location;
      cardData["Launches: "] = object.num_launches;
      cardData["Fastest Turnaround: "] = object.fastest_turnaround;
      cardData["Status: "] = toTitleCase(object.status);
      break;

    case "recoveryzone":
      cardData["Nickname: "] = object.nickname;
      if (object.serial_number) {
        cardData["Boat ID: "] = object.serial_number;
      }
      cardData["Landings: "] = object.num_landings;
      cardData["Fastest Turnaround: "] = object.fastest_turnaround;
      cardData["Status: "] = toTitleCase(object.status);
      break;

    case "padInfo":
      cardData["Rocket: "] = subObjects.rockets.find((rocket) => rocket.id === object.rocket)?.name;
      cardData["Liftoff Time: "] = formatTime(object.time.slice(0, -1));
      cardData["Launch Site: "] = subObjects.pads.find((pad) => pad.id === object.pad)?.nickname;
      cardData["Pad Turnaround: "] = object.pad_turnaround
        ? `${Math.round((object.pad_turnaround / 86400) * 100) / 100} days`
        : "None";
      cardData[`Outcome: `] = toTitleCase(object.launch_outcome) || "None";
      break;

    case "zoneInfo":
      cardData["Time: "] = formatTime(object.launch.time.slice(0, -1));
      cardData["Turnaround Time: "] = object.zone_turnaround
        ? `${Math.round((object.zone_turnaround / 86400) * 100) / 100} days`
        : "None";
      cardData["Launch Site: "] = subObjects.pads.find((pad) => pad.id === object.launch.pad)?.nickname;
      cardData["Landing Location: "] =
        subObjects.zones.find((zone) => zone.id === object.landing_zone)?.nickname || "N/A";
      break;

    default:
      break;
  }

  return cardData;
};

const InformationCard = ({ object, objectType, subObjects, additionalInfo }) => {
  let url = `/${objectType}/${object.id}/`;
  let backgroundImage = `url(${getPhotoLocation()}${object.image})`;
  let name = object.name;

  // Set additional info
  const cardData = setCardData(object, objectType, subObjects);

  if (objectType === "spacecraftInfo" || objectType === "stageInfo" || objectType === "zoneInfo") {
    url = `/launch/${object.launch.id}/`;
    backgroundImage = `url(${getPhotoLocation()}${object.launch.image.replace("http://127.0.0.1:8000/", "")})`;
    name = object.launch.name;
  } else if (objectType === "padInfo") {
    url = `/launch/${object.id}/`;
    name = object.name;
  }

  if (additionalInfo) {
    for (const [key, value] of Object.entries(additionalInfo)) {
      cardData[key] = value;
    }
  }

  return (
    <Link to={url} className="launch-link">
      <div className="launch-card">
        <div
          className="launch-image"
          style={{
            backgroundImage: backgroundImage,
          }}
        ></div>
        <div className="launch-info">
          <h1>{name}</h1>
          {Object.entries(cardData || {}).map(([key, value]) => (
            <p key={key}>
              <b>{key}</b>
              {value}
            </p>
          ))}
        </div>
      </div>
    </Link>
  );
};

export default InformationCard;
