import React from "react";
import { getPhotoLocation } from "../utils";
import { Link } from "react-router-dom";
import "./Card.css";

const StageAndRecoveryCard = ({ stageAndRecoveryData, launchInFuture }) => {
  let stageNameString = "";
  if (stageAndRecoveryData.stage) {
    stageNameString = `${stageAndRecoveryData.stage.name}-${stageAndRecoveryData.num_flights} `;
  }

  // List of phrases for each recovery type (future, success, failure, precluded)
  const recoveryPhrases = {
    GROUND_PAD: {
      FUTURE: "will land",
      SUCCESS: "successfully landed",
      FAILURE: "failed to land",
      PRECLUDED: "was precluded from landing",
    },
    DRONE_SHIP: {
      FUTURE: "will land",
      SUCCESS: "successfully landed",
      FAILURE: "failed to land",
      PRECLUDED: "was precluded from landing",
    },
    OCEAN_SURFACE: {
      FUTURE: "will softly splashdown",
      SUCCESS: "softly splashed down",
      FAILURE: "failed to softly splashdown",
      PRECLUDED: "was precluded from a soft splashdown",
    },
    PARACHUTE: {
      FUTURE: "will be recovered by parachute",
      SUCCESS: "was recovered by parachute",
      FAILURE: "failed to be recovered by parachute",
      PRECLUDED: "was precluded from parachute recovery",
    },
    EXPENDED: {
      FUTURE: "will be expended",
      SUCCESS: "was expended",
      FAILURE: "was expended",
      PRECLUDED: "was precluded from being expended",
    },
    CATCH: {
      FUTURE: "will be caught",
      SUCCESS: "was caught",
      FAILURE: "failed to be caught",
      PRECLUDED: "was precluded from catching",
    },
  };

  const getPhrase = (method, status, future) => {
    const phrases = recoveryPhrases[method];
    return future ? phrases.FUTURE : phrases[status] || "";
  };

  stageNameString += getPhrase(stageAndRecoveryData.method, stageAndRecoveryData.method_success, launchInFuture);

  return (
    <div className="stage-and-recovery-card launch-width">
      {stageAndRecoveryData.stage && (
        <Link to={`/stage/${stageAndRecoveryData.stage.id}`}>
          <div className="stage-card">
            <div
              className="launch-image"
              style={{
                backgroundImage: `url(${getPhotoLocation()}${stageAndRecoveryData.stage.image.replace(
                  "http://127.0.0.1:8000",
                  ""
                )})`,
              }}
            ></div>

            {/* Stage Info */}
            <div className="stage-info">
              <h2>{stageNameString}</h2>
              {stageAndRecoveryData.stage_position !== "SINGLE_CORE" && (
                <p>
                  <b>Position: </b>
                  {stageAndRecoveryData.stage_position.replace("CENTER", "Center")}
                </p>
              )}
              <p>
                <b>Turnaround: </b>
                {stageAndRecoveryData.stage_turnaround}
              </p>
              {stageAndRecoveryData.stage_stats.map(([important, stat], index) => {
                const [boldText, ...rest] = stat.split(" ");
                const remainingText = rest.join(" ");

                return (
                  <p key={index} id={stat}>
                    <strong>{boldText}</strong> {remainingText}
                  </p>
                );
              })}
            </div>
          </div>
        </Link>
      )}

      {/* Landing zone card (if available) */}
      {stageAndRecoveryData.landing_zone && (
        <Link to={`/recoveryzone/${stageAndRecoveryData.landing_zone.id}`}>
          <div className="landing-zone-card">
            <div className="landing-zone-info">
              <h2>{stageAndRecoveryData.landing_zone.name}</h2>
              <p>
                <b>Turnaround: </b>
                {stageAndRecoveryData.zone_turnaround}
              </p>
              {stageAndRecoveryData.landing_zone_stats.map(([important, stat], index) => {
                const [boldText, ...rest] = stat.split(" ");
                const remainingText = rest.join(" ");

                return (
                  <p key={index} id={stat}>
                    <strong>{boldText}</strong> {remainingText}
                  </p>
                );
              })}
            </div>
            <div
              className="launch-image"
              style={{
                backgroundImage: `url(${getPhotoLocation()}${stageAndRecoveryData.landing_zone.image.replace(
                  "http://127.0.0.1:8000",
                  ""
                )})`,
              }}
            ></div>
          </div>
        </Link>
      )}
    </div>
  );
};

export default StageAndRecoveryCard;
