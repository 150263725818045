import { getHostName, convertToUTC } from "../utils";

export async function fetchZones(query, filter) {
  const jsonString = encodeURIComponent(JSON.stringify(filter));
  const data = await fetch(`${getHostName()}/api/landingzones/?format=json&filter=${jsonString}&query=${query}`);
  return await data.json();
}

export async function fetchZoneInfo(id, startDate) {
  const data = await fetch(
    `${getHostName()}/api/landingzoneinformation/?id=${id}${
      startDate ? `&startdate=${convertToUTC(startDate)}` : ""
    }&format=json`
  );

  if (!data.ok) {
    throw new Error("Recovery Zone Not Found");
  }

  return await data.json();
}
